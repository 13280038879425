import React from 'react'

const aspectRatio = 450.817 / 1920

const BottomPageIllustration = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 450.817" {...props}>
      <defs>
        <clipPath id="BottomPageIllustrationa">
          <path data-name="Ret\xE2ngulo 528" fill="none" d="M0 0h1920v450.817H0z" />
        </clipPath>
      </defs>
      <g data-name="Grupo 11">
        <g data-name="Grupo 10" clipPath="url(#BottomPageIllustrationa)">
          <path
            data-name="Caminho 11"
            d="M983.211 309.667c597.646-141.618 1182.814-188.5 1647.7-132.02 237.229 28.837 433.626 83.536 583.748 162.605 124.354 65.5 282.288 184.475 328.476 379.4 46.333 195.515-40.541 372.666-121.584 486.846-98.09 138.208-248.547 275.175-447.218 407.137-388.408 257.977-933.54 478.553-1534.967 621.067-597.317 141.544-1182.552 188.265-1647.893 131.583-237.171-28.9-433.546-83.61-583.653-162.614-124.368-65.448-282.295-184.328-328.4-378.9-46.115-194.6 41.684-371.7 123.45-485.995 98.7-137.967 249.639-274.977 448.631-407.231C-158.08 672.068 385.893 451.214 983.21 309.669"
            fill="#00142e"
          />
          <path
            data-name="Caminho 12"
            d="M1231.736 262.466c538.617-46.443 1054.183-12 1451.722 96.981 202.861 55.619 366.844 128.515 487.386 216.664 99.86 73.035 222.078 196.958 237.222 372.616 15.194 176.21-83.238 319.293-168.493 408.295-103.184 107.716-251.819 207.642-441.782 296.979-371.391 174.688-874.438 296.63-1416.465 343.364-538.325 46.414-1053.921 11.828-1451.831-97.39-202.8-55.669-366.756-128.565-487.3-216.663-99.875-72.991-222.108-196.813-237.229-372.179s84.112-318.288 170.009-407.3c103.693-107.439 252.751-207.321 443.034-296.891 373.33-175.723 875.407-298.064 1413.725-344.478"
            fill="#1700f4"
          />
          <path
            data-name="Caminho 13"
            d="M1471.384 232.806c526.636 67.54 1014.321 207.918 1373.192 395.272 183.134 95.6 325.349 199.705 422.7 309.411 80.643 90.875 172.152 235.261 150.129 407.02-22.1 172.291-146.362 289.131-246.71 356.809-121.46 81.911-284.925 146.879-485.848 193.089-392.831 90.357-901.045 102.688-1431.019 34.718-526.352-67.5-1014.029-208.028-1373.214-395.68-183.069-95.648-325.254-199.742-422.622-309.4-80.665-90.846-172.2-235.13-150.217-406.6s146.984-287.981 247.958-355.536c121.89-81.539 285.749-146.383 487.029-192.739 394.908-90.955 902.269-103.868 1428.621-36.364"
            fill="#00095b"
          />
        </g>
      </g>
    </svg>
  )
}

export default BottomPageIllustration
